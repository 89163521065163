import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import NavPagination from "../../components/NavPagination";
import Operation from "../../components/Operation";
import ReturnTitle from "../../components/utils/ReturnTitle";
import { customPrice } from "../../helpers/all";
import Meta from "../../components/Meta";
import { createTransaction, getTransactions } from "../../services/transaction"
import Loader from '../../components/utils/Loader';
import Table from "react-bootstrap/Table";
import { NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom';

const Finance = () => {
  const [tab, setTab] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [price, setPrice] = useState()
  const onPageChange = (page) => {
    setCurrentPage(page.selected + 1);
  };
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [transactions, setTransactions] = useState({
    loading: true,
    items: [],
  });
  const onPriceUp = useCallback((price) => {
    setLoading(true);
    createTransaction({ price: price })
      .then((res) => {
        if (res.data?.link) {
          return window.location.replace(res.data.link);
        }
        setLoading(false);
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error === "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        )
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    getTransactions({ page: currentPage, size: 10 })
      .then((res) => {
        setTransactions((prev) => ({
          prev,
          loading: false,
          ...res,
        }))
        setCurrentPage(res.pagination.currentPage)
      })
      .catch(() => setOrders((prev) => ({ ...prev, loading: false })));
  }, [currentPage]);
  const [balanceSection, setBalanceSection] = useState(2);
  if (transactions.loading) {
    return <Loader full />;
  }
  return (
    <div className="main">
      <Meta title="Финансы" />
      <ReturnTitle link="/account" title="Финансы" />

      <div className="tabs-group mb-4 mb-sm-5">
        <button type="button" className={tab === 0 ? 'active' : ''} onClick={() => setTab(0)}>
          Баланс
        </button>
        <button type="button" className={tab === 1 ? 'active' : ''} onClick={() => setTab(1)}>
          История списаний и пополнений
        </button>
      </div>
      {tab === 0 ? (
        <form action="">
          <fieldset>
            <legend>Сумма:</legend>
            <div className="d-flex align-items-center">
              <div className="me-2 me-sm-4">Укажите необходимую сумму:</div>
              <input
                type="number"
                placeholder="0"
                value={price}
                onChange={(e) => { setPrice(e.target.value) }}
              />
              <div className="ms-3">руб.</div>
            </div>
          </fieldset>
          <div className='d-sm-flex gap-3 mt-4 mt-sm-5'>
            <button type="button" disabled={!price || price <= 0} className="btn-5 w-xs-100 mb-2 mb-sm-0" onClick={() => onPriceUp(price)}>
              Пополнить
            </button>
            <button type="button" disabled={true} className="btn-5 w-xs-100">
              Вывести
            </button>
          </div>
        </form>
      ) : (
        <div className='d-flex flex-column h-100'>
          <h6>Ваши списания и пополнения:</h6>
          <Table responsive borderless className="my-3 my-sm-4 h-100">
            <tbody>
              {transactions.loading ? (
                <tr>
                  <td className='py-5 text-center text-muted'>Загрузка операций...</td>
                </tr>
              ) : (
                transactions?.items.map((item, index) => (
                  <Operation key={index} {...item} />
                ))
              )}
            </tbody>
          </Table>
          <NavPagination totalPages={transactions?.pagination?.totalPages} onPageChange={onPageChange} />
        </div>
      )}
    </div>
  );
};

export default Finance;
