import React, { useCallback, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import 'swiper/css';
import 'swiper/css/scrollbar';
import BlogSection from "../components/BlogSection";
import CatalogSection from "../components/CatalogSection";
import MainSlider from "../components/MainSlider";
import Meta from "../components/Meta";
import Chat from "../components/chat/Chat";
import ScrollToTopButton from "../components/utils/ScrollToTopButton";
import socket from "../config/socket";
import { createMessageGeneral, getMessagesGeneral } from "../services/message";
import { useGetArticlesQuery, useGetGamesQuery, useGetSalesQuery } from "../store/reducers/homeQuery";
import Loader from "../components/utils/Loader";

const Home = () => {
  const home = useGetGamesQuery();
  const articles = useGetArticlesQuery();
  const sales = useGetSalesQuery();
  const [messages, setMessages] = useState({
    loading: true,
    items: [],
  });

  useEffect(() => {
    getMessagesGeneral()
      .then((res) =>
        setMessages((prev) => ({
          ...prev,
          loading: false,
          items: res.messages.items,
          count: res.countOnline,
        }))
      )
      .catch(() => setMessages((prev) => ({ ...prev, loading: false })));
  }, []);

  const userId = useSelector(state => state.auth?.user?.id);
  const { control, reset, setValue } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      id: "general",
    },
  });

  const data = useWatch({ control });


  useEffect(() => {
    socket.emit("createRoom", "message/" + data.id);

    socket.on("message", (data) => {
      setMessages(prev => {
        if (data.status) {
          return {
            ...prev,
            loading: false,
            items: [data, ...prev.items],
          };
        } else {
          const messageIndex = prev.items.findIndex(item => item.id === data.id);

          if (messageIndex !== -1) {
            const updatedMessages = [...prev.items];
            updatedMessages[messageIndex] = data;

            return {
              ...prev,
              loading: false,
              items: updatedMessages,
            };
          }

          return prev;
        }
      });
    });

    return () => {
      socket.emit("removeRoom", "message/" + data.id);
      socket.off("message");
    };


  }, [userId]);

  const onNewMessage = useCallback(
    (text) => {
      createMessageGeneral({ ...data, text });
    },
    [data]
  );


  if (home?.isLoading || articles.isLoading || sales.isLoading) {
    return <Loader />;
  }

  return (
    <main>
      <Meta title="Каталог" />
      {
        (sales?.data?.home?.items?.length > 0) &&
        <div className="px-mobile-0 container-md">
          <MainSlider data={sales.data.home.items} />
        </div>
      }
      <ScrollToTopButton />
      <CatalogSection games={home.data} />

      <section className='sec-bottom mb-6'>
        <Container className="">
          <Row className="flex-lg-row-reverse">
            <Col xs={12} lg={5} xxl={4} className="d-none d-lg-block">
              <div className="d-flex justify-content-between align-items-baseline">
                <h2 className="mb-0">Новости</h2>
                <Link to="/blog">все новости</Link>
              </div>
              <BlogSection articles={articles?.data?.items} />
            </Col>
            <Col xs={12} lg={7} xxl={8} className="pe-xxl-5 ">
              <section className="sec-chat">
                <Chat
                  general="general"
                  messages={messages}
                  emptyText="Нет сообщений"
                  onSubmit={(e) => onNewMessage(e)}
                  onChange={(e) => setValue("text", e)}
                />
              </section>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default Home;
