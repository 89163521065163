import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import bgImg from '../assets/imgs/bg/gradient.jpg';

const AgencyContract = () => {
  return (
    <main>
      <Container>
        <section className='rules mb-md-5'>
          <Row className='justify-content-center'>
            <div className='topic mb-4 mb-md-5'>
              <h1 className='h2 mb-0'>Агентский договор</h1>
            </div>
            <div className='rules-wrapping mb-5'>
              <div className='rules-wrapping-top'>
              </div>
              <div className='rules-wrapping-main'>
                <Row className='gx-3 gx-md-4 gx-lg-5' >
                  <span>Настоящий агентский договор (далее — Договор), является публичной офертой ИП Разуваева Елена Сергеевна ИНН: 323203455433 ОГРН: 324320000033480 (далее – SpeedyTrade, Агент) на совершение расчетов от имени и за счет Покупателя на сайте https://speedytrade.ru (далее – Сайт) на условиях, приведенных ниже.</span>
                  <span>Термины и определения, используемые в настоящем Договоре, раскрыты в заключенном Сторонами Лицензионном соглашении, размещенном по адресу: https://speedytrade.ru/license-agreement.</span>
                  <span>1.	ПРЕДМЕТ ДОГОВОРА</span>
                  <span>1.1.	Покупатель в качестве Принципала поручает SpeedyTrade в качестве Агента совершать от имени Покупателя и за его счет расчеты по исполнению Сделок, заключаемых через Сайт Покупателем с Продавцами, и обязуется выплачивать Агенту агентское вознаграждение.</span>
                  <span>1.2.	Оформление Покупателем Сделки с Продавцом через Сервис Сайта означает автоматическое поручение Покупателем Агенту в соответствии с Договором.</span>
                  <span>1.3.	Агент вправе переуступать либо каким-либо иным способом передавать свои права и обязанности, вытекающие из его отношений с Покупателем, третьим лицам.</span>
                  <span>2.	ВОЗНАГРАЖДЕНИЕ И РАСХОДЫ АГЕНТА</span>
                  <span>2.1.	Вознаграждение Агента выплачивается отдельно по каждому поручению и состоит из двух пунктов:</span>
                  <span>безусловная часть вознаграждения;</span>
                  <span>вознаграждение по результатам исполнения поручения.</span>
                  <span>2.2.	Размер вознаграждение Агента, а также каждой его части, зависит от цены поручения (суммы, подлежащей перечислению Продавцу по указанию Покупателя).</span>
                  <span>2.3.	Размер безусловной части вознаграждения Агента по одному поручению не может превышать 5% от цены поручения.</span>
                  <span>2.4.	Размер вознаграждения Агента по результатам исполнения поручения по одному поручению не может превышать 5% от цены поручения.</span>
                  <span>2.5.	Общая сумма вознаграждения Агента по одному поручению не может превышать 10% от цены поручения.</span>
                  <span>2.6.	Расходы, понесенные Агентом для исполнения Агентский договора, компенсируются по мере их несения.</span>
                  <span>3.	ПОРЯДОК ВЗАИМОДЕЙСТВИЯ СТОРОН</span>
                  <span>3.1.	Покупатель, выдавший поручение Агенту, перечисляет Агенту денежные средства для исполнения соответствующего поручения, размер которых включает:</span>
                  <span>сумму, подлежащую перечислению Продавцу;</span>
                  <span>вознаграждение Агента.</span>
                  <span>3.2.	При перечислении Покупателем денежных средств Агенту, Покупатель дополнительно оплачивает комиссию платежной системы, которая удерживается платежной системой при перечислении.</span>
                  <span>3.3.	Агент удерживает из суммы, полученной от Покупателя, безусловную часть вознаграждения Агента и помещает оставшуюся сумму на депозит до получения дополнительных указаний Покупателя.</span>
                  <span>3.4.	После получения указания Покупателя о перечислении средств Продавцу, Агент перечисляет средства депозита за минусом суммы вознаграждения по результатам исполнения поручения на Кошелек Продавца и предоставляет Покупателю отчет об исполнении поручения.</span>
                  <span>3.5.	Моментом исполнения Агентом поручения считается момент зачисления суммы, подлежащую перечислению Продавцу, на Кошелек Продавца.</span>
                  <span>3.6.	Отчетом Агента по конкретному поручению является информация в Личном кабинете Покупателя о произведенных расчетах с Продавцом. Доказательства расходов, произведенных Агентом за счет Покупателя, в отчете агента не указываются.</span>
                  <span>3.7.	Отчет Агента размещается в Личном кабинете Покупателя.</span>
                  <span>3.8.	Покупатель, имеющий возражения по отчету Агента, должен сообщить о них в течение 24 часов. В противном случае отчет Агента считается принятым Покупателем.</span>
                  <span>3.9.	Покупатель вправе отказаться от поручения в любой момент до выдачи указания Агенту о перечислении средств Продавцу.</span>
                  <span>3.10.	В случае отказа Покупателя от поручения после размещения Агентом средств на депозите и до выдачи указания Агенту о перечислении средств Продавцу, вся сумма депозита (сумма, подлежащую перечислению Продавцу, и сумма вознаграждения по результатам исполнения поручения) переводится Агентом на его Кошелек, безусловная часть вознаграждения Агента не возвращается.</span>
                  <span>3.11.	В случае последующей отмены Сделки и возврата средств Покупателю в случаях, предусмотренных Лицензионным соглашением, такой возврат производится на условиях, определяемых Лицензионным соглашением, и не включает вознаграждение Агента, т.е. возврату подлежит только сумма, которая подлежала перечислению Продавцу по указанию Покупателя.</span>
                  <span>4.	ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ</span>
                  <span>4.1.	Агент не несет ответственности за нарушение сроков, указанных в разделе 3 Договора в случае частичной или полной недоступности Сайта и его Сервисов по причине проведения профилактических или иных работ, или по любым другим причинам технического характера.</span>
                  <span>4.2.	В случае проведения таких работ или наступления таких причин, сроки, указанные в разделе 3 Договора, продлеваются на срок выполнения соответствующих работ или устранения причин, вызвавших частичную или полную недоступность Сайта и/или его Сервисов.</span>
                  <span>4.3.	Агент не несет никаких иных дополнительных гарантий в отношении работы Сайта и его Сервисов, а также в связи с использованием или невозможностью использования Сайта и/или его Сервисов, сверх гарантий, установленных Лицензионным соглашением.</span>
                  <span>4.4.	Агент не является стороной или заинтересованным лицом по Сделке между Покупателем и Продавцом, а потому не несет ответственность за последствия этой Сделки. Ответственность Агента ограничивается ответственностью, предусмотренной по Договору.</span>
                  <span>5.	ПОРЯДОК ЗАКЛЮЧЕНИЯ И ИЗМЕНЕНИЯ ДОГОВОРА, СРОК ЕГО ДЕЙСТВИЯ</span>
                  <span>5.1.	Принимая Договор путем нажатия соответствующей кнопки в конце текста Договора или иным способом, предусмотренным интерфейсом Сайта, Покупатель дает полное и безоговорочное согласие на условия, изложенные в Договоре.</span>
                  <span>5.2.	Принимая условия Договора, Покупатель подтверждает, что обладает дееспособностью, правами и полномочиями на заключение Договора и полностью понимает и осознает значение своих действий.</span>
                  <span>5.3.	Договор вступает в силу с момента его заключения (акцепта) и действует до исполнения Сторонами своих обязательств по нему или наступления следующих событий:</span>
                  <span>расторжения Сторонами Лицензионного соглашения;</span>
                  <span>принятия решения Покупателем о расторжении Договора.</span>
                  <span>5.4.	Агент вправе в любое время и по своему усмотрению вносить изменения в Договор. В случае внесения изменений в Договор обновленная версия публикуется на Сайте в разделе https://speedytrade.ru/agency.</span>
                  <span>Обновленная версия Договора вступает в силу с даты публикации. Актуальная дата всегда будет указана непосредственно под текстом документа.</span>
                  <span>Покупатель получает уведомление об изменении Договора по адресу электронной почты, указанной им при регистрации. Продолжение использования Покупателем Сервисов Сайта после публикации изменений в Договоре означает его согласие с данными изменениями.</span>
                  <span>5.5.	Если Покупатель не согласен с каким-либо условием Договора или оно ему непонятно, он не должен акцептовать Договор, предусмотренным на Сайте способом, до устранения неясностей или несогласия.</span>
                  <span>6.	ИНЫЕ ПОЛОЖЕНИЯ</span>
                  <span>6.1.	Независимо от места нахождения Покупателя, применимым правом по Договору является право Российской Федерации, в соответствии с которым разрешаются все споры и разногласия между Сторонами.</span>
                  <span>6.2.	Стороны будут стремиться к разрешению споров и разногласий по Договору или в связи с ним, путем переговоров.</span>
                  <span>6.3.	Если по тем или иным причинам одно или несколько положений Договора будут признаны недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или применимость остальных положений Договора.</span>
                  <span>6.4.	Стороны признают юридическую силу за: электронными письмами и документами, направленными по электронной почте (cо стороны Агента это адрес электронной почты, указанный на Сайте, со стороны Покупателя - адрес электронной почты, указанный при регистрации); согласиями и гарантиями, полученными от Покупателя с использованием элементов интерфейса Сайта.</span>

                </Row>
              </div>
            </div>
          </Row>
        </section>
      </Container>
    </main>
  );
};

export default AgencyContract;