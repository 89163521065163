import React from 'react';
import moment from "moment";
import { customPrice } from '../helpers/all';

const Operation = ({ createdAt, orderId, id, status, price, total }) => {
  return (
    <tr className='table-line'>
      <td>
        <time>
          <span>{moment(createdAt).format("DD.MM.YYYY")}</span>
          <span className='ms-3 gray'>{moment(createdAt).format("kk:mm")}</span>
        </time>
      </td>
      <td><span className='d-xl-none'>ID:</span>{id}</td>

      {orderId ?
        (price < 0 ? (
          <td>Покупка</td>
        ) : (
          <td>Продажа</td>
        )) : (
          price > 0 ? (
            <td>Пополнение</td>
          ) : (
            <td>Вывод средств</td>
          ))
      }

      <td>
        {status == "ok" ?
          <span className="blue">Исполнено</span>
          : status == "refund" ?
            <span className="blue">Отменено</span>
            : <span className="green">В обработке</span>

        }
      </td>
      {price < 0 ?
        <td>{customPrice(total)}</td>
        :
        <td>{customPrice(price)}</td>
      }
    </tr >
  );
};

export default Operation;