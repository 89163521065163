import React from 'react';
import Container from 'react-bootstrap/Container';
import bgImg from '../assets/imgs/bg/gradient.jpg';
import { Row } from 'react-bootstrap';

const PrivacyPolicy = () => {
  return (
    <main>
      <Container>
        <section className='rules mb-md-5'>
          <Row className='justify-content-center'>
            <div className='topic mb-4 mb-md-5'>
              <h1 className='h2 mb-0'>Политика конфиденциальности</h1>
            </div>
            <div className='rules-wrapping mb-5'>
              <div className='rules-wrapping-top'>
              </div>
              <div className='rules-wrapping-main'>
                <Row className='gx-3 gx-md-4 gx-lg-5' >
                  <span>Настоящая политика конфиденциальности (далее – Политика) действует в отношении персональных данных, которые могут быть получены ИП Разуваева Елена Сергеевна ИНН: 323203455433 ОГРН: 324320000033480 (далее – SpeedyTrade) в процессе использования вами:</span>
                  <span>веб-сайта https://speedytrade.ru, принадлежащего SpeedyTrade, и его поддоменов (далее вместе — Сайт),</span>
                  <span>любых программ, продуктов и/или сервисов, предоставляемых на Сайте (далее вместе — Сервисы), а также в ходе исполнения SpeedyTrade любых соглашений и договоров, заключённых с вами в связи с использованием вами Сервисов на Сайте.</span>
                  <span>1.	ОБЩИЕ ПОЛОЖЕНИЯ</span>
                  <span>1.1. Персональные данные – любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных);</span>
                  <span>1.2. Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;</span>
                  <span>Действия и операции, которые SpeedyTrade осуществляет с персональными данными, зависят от используемого вами Сервиса и могут быть доведены до вас до начала обработки при помощи соответствующих юридических документов (оферт, условий использования и т.д.) или элементов пользовательского интерфейса.</span>
                  <span>1.3. SpeedyTrade обрабатывает только те данные и только теми способами, которые перечислены в Политике и которые характеризуют вас как пользователя Сайта и/или Сервисов. При обработке персональных данных SpeedyTrade руководствуется нормами международного законодательства и законодательства Российской Федерации.</span>
                  <span>1.4. Ваше обращение к Сайту означает ваше согласие с Политикой и ее условиями обработки персональных данных. Вы также можете дать согласие на обработку ваших персональных данных при заполнении формы регистрации на Сайте и иными способами, предусмотренными Политикой.</span>
                  <span>В случае несогласия с Политикой вы должны воздержаться от использования Сайта SpeedyTrade.</span>
                  <span>1.5. SpeedyTrade не может проверить и потому доверяет вам в том, что вы:</span>
                  <span>обладаете полной дееспособностью;</span>
                  <span>предоставили собственные достоверные персональные данные.</span>
                  <span>1.6. SpeedyTrade вправе вносить изменения в Политику, в т.ч. в связи с изменениями законодательства о защите персональных данных, без уведомления.</span>
                  <span>При внесении изменений обновленная версия Политики публикуется на Сайте в разделе https://speedytrade.ru/privacy-policy и вступает в силу с даты публикации.</span>
                  <span>SpeedyTrade рекомендует периодический просмотр актуальной версии Политики. Использование Сайта после публикации изменений в Политике означает ваше согласие с данными изменениями.</span>
                  <span>1.7. В случае существенных изменений в Политике мы сообщим вам об этом. SpeedyTrade может попросить дать ваше согласие на продолжение обработки персональных данных, даже если вы давали подобное согласие ранее.</span>
                  <span>1.8. Вы можете обратиться к SpeedyTrade по любым вопросам, связанным с исполнением или изменением Политики, а также обработкой персональных данных:</span>
                  <span>по электронной почте: support@speedytrade.ru</span>
                  <span>воспользовавшись формой обратной связи https://speedytrade.ru/account/callback (для Авторизированных пользователей);</span>
                  <span>При обращении укажите тему «Обработка персональных данных».</span>
                  <span>SpeedyTrade ответит на ваше обращение не позднее 10 рабочих дней с момента его получения.</span>
                  <span>2.	ВАШИ ПРАВА</span>
                  <span>2.1. В любой момент, когда у SpeedyTrade есть ваши персональные данные, вы можете воспользоваться следующими правами:</span>
                  <span>Право на доступ к ваше персональной информации.</span>
                  <span>Право на исправление – у вас есть право запросить SpeedyTrade исправить ваши неточные и/или неполные персональные данные;</span>
                  <span>Право на отзыв – вы вправе в любой момент времени отозвать свое согласие на обработку персональных данных;</span>
                  <span>Право на забвение – вы вправе запросить удаление персональных данных, которые есть в отношении вас у SpeedyTrade, за исключением случаев, когда SpeedyTrade обязан хранить такие данные по закону.</span>
                  <span>Вы можете требовать ограничений на обработку вашей Персональной информации.</span>
                  <span>Если вы хотите, чтобы какие-либо ваши персональные были удалены, вы можете обратиться к нам с соответствующим запросом</span>
                  <span>Вы можете обратиться к SpeedyTrade по любым вопросам, связанным с исполнением или изменением Политики, а также обработкой персональных данных.</span>
                  <span>В случаях, предусмотренных применимым законодательством, вы можете также обладать другими правами, не указанными выше.</span>
                  <span>При использовании вами Сервисов передача Персональной информации осуществляется с вашего согласия, выражающего свободную волю и ваш интерес (в частности, на основании договора с вами), и не является обязанностью по закону.</span>
                  <span>2.2. Вы также вправе обратиться к SpeedyTrade, если захотите уточнить порядок реализации иных прав, предусмотренных Федеральным законом «О персональных данных».</span>
                  <span>3.	ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ</span>
                  <span>3.1. SpeedyTrade обрабатывает персональные данные в цифровой форме (автоматизированно), ограничиваясь следующими действиями:</span>
                  <span>сбор;</span>
                  <span>хранение;</span>
                  <span>систематизация;</span>
                  <span>накопление;</span>
                  <span>предоставление третьим лицам;</span>
                  <span>уточнение (изменение, обновление);</span>
                  <span>уничтожение персональных данных.</span>
                  <span>3.2. Обработка персональных данных производится SpeedyTrade с использованием баз данных, находящихся на территории Российской Федерации.</span>
                  <span>3.3. Цели и правовая основа обработки персональных данных:</span>
                  <span>обеспечение работы Сайта;</span>
                  <span>соблюдение обязательств установленных законодательством;</span>
                  <span>выполнение договорных обязательств перед пользователями на Сайте и Сервисах;</span>
                  <span>для улучшения качества оказываемых услуг;</span>
                  <span>для обеспечения законных интересов SpeedyTrade только в тех случаях, если такая обработка не оказывает существенного влияния и не наносит ущерб вашим интересам, правам и свободам</span>
                  <span>3.4. Данные которые мы собираем:</span>
                  <span>- адрес электронной почты;</span>
                  <span>- IP-адрес;</span>
                  <span>- cookie-файлы;</span>
                  <span>- данные вашей учетной записи;</span>
                  <span>- данные о местоположении;</span>
                  <span>- данные о браузере;</span>
                  <span>- User-Agent (данные об устройстве);</span>
                  <span>- информация о вашей активности во время использования Сайта и/или Сервисов;</span>
                  <span>- номера банковских карт для покупок и/или оплат товаров и услуг;</span>
                  <span>- ваше имя.</span>
                  <span>3.4. SpeedyTrade осуществляет запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных с использованием баз данных, находящихся на территории Российской Федерации, в течение срока, необходимого для достижения указанной цели или отзыва согласия на их обработку.</span>
                  <span>3.5. По истечении срока обработки персональных данных они удаляются или анонимизируются.</span>
                  <span>4.	КОНФИДЕНЦИАЛЬНОСТЬ ПЕРСОНАЛЬНЫХ ДАННЫХ</span>
                  <span>4.1. SpeedyTrade обязуется обеспечивать конфиденциальность ваших персональных данных, т.е. не распространять и не раскрывать третьим лицам ваши персональные данные за исключением следующих случаев:</span>
                  <span>в рамках заключения и исполнения с вами различных договоров, в т.ч. условий использования отдельных Сервисов;</span>
                  <span>для исполнения положений законодательства по раскрытию персональных данных в определенном обмене (например, по требованию налоговых органов, по запросу суда или в иных случаях);</span>
                  <span>с вашего согласия;</span>
                  <span>при наличии иных правовых оснований в соответствии с законодательством.</span>
                  <span>Обратите внимание, что уровень защиты Персональной информации в некоторых странах может не совпадать с уровнем, установленном в вашей юрисдикции, и, используя наши Сайт и/или Сервисы, вы соглашаетесь на такую передачу.</span>
                  <span>4.2. К числу третьих лиц, которым SpeedyTrade может передавать ваши персональные данные, относятся банки и операторы платежных систем при проведении расчетов на Сайте, государственные органы, запрашивающие персональные данные в пределах своей компетенции. Такие лица обрабатывают ваши персональные данные не от имени SpeedyTrade или в его интересах, поскольку имеют собственные цели и правовые основания такой обработки.</span>
                  <span>4.3. Вы также соглашаетесь, что SpeedyTrade вправе поручить обработку ваших персональных данных третьим лицам на основании заключаемых с ними договоров. Это означает, что SpeedyTrade может делегировать часть своих функций по обработке персональных данных определенному лицу, которое при этом будет действовать от имени или в интересах SpeedyTrade. Например, в случае необходимости в дополнительных вычислительных мощностях, SpeedyTrade может арендовать выделенные серверы у третьего лица в отсутствие собственных серверов.</span>
                  <span>4.4. Когда это необходимо для защиты и/или реализации собственных прав и законных интересов, SpeedyTrade может использовать сторонние сервисы и привлекать третьих лиц для проверки информации о вас, передавая им такую информацию. Таким образом SpeedyTrade, например, может выявлять и устранять финансовые и юридические риски, бороться с мошенническим или иным недобросовестным поведением пользователей Сервисов и предотвращать угрозы информационной безопасности, которые могут возникать в отношении Сервисов.</span>
                  <span>5.	ФАЙЛЫ COOKIE</span>
                  <span>5.1. Файлы cookie – это небольшие фрагменты данных с определённой (часто технической) информацией, которые Сайт передает вашему браузеру, а ваш браузер хранит и передает обратно Сайту с каждым вашим запросом к нему.</span>
                  <span>5.2. С помощью файлов cookie, SpeedyTrade собирает такую информацию, как время, проведенное на Сайте, области посещения Сайта, веб-сайты со ссылками на наш Сайт и иную дополнительную информацию.</span>
                  <span>Обращаем ваше внимание, что файлы SpeedyTrade не используются для сбора личной информации о вас.</span>
                  <span>5.3. Структура файла cookie, его содержание и технические параметры определяются Сайтом и могут быть изменены без направления вам предварительного уведомления.</span>
                  <span>5.4. SpeedyTrade применяет файлы cookie для:</span>
                  <span>запоминания пользователей Сайта и сохранения их персональных предпочтений и настроек;</span>
                  <span>предоставления им персонализированных Сервисов;</span>
                  <span>таргетирования рекламы;</span>
                  <span>статистических и исследовательских целей;</span>
                  <span>усовершенствования Сайта и Сервисов на основе пользовательских предпочтений.</span>
                  <span>5.5. Обращаем Ваше внимание, что оборудование и программное обеспечение, используемые вами для посещения сайтов, как правило обладают функцией запрета операций с файлами cookie для любых или для определенных веб-сайтов, а также удаления ранее полученных файлов cookie.</span>
                  <span>В любое время вы можете самостоятельно удалить сохраненные на вашем компьютере файлы cookie, изменить параметры в настройках вашего браузера таким образом, чтобы ваш браузер перестал сохранять все файлы cookie и оповещал их об отправке.</span>
                  <span>5.6. Отказ или ограничение в использовании файлов cookie не является препятствием для использования Сайта, однако в этом случае некоторые функции Сайта и Сервисы могут быть недоступны или работать с определенными ограничениями. SpeedyTrade оставляет за собой право установить, что предоставление определенного Сервиса возможно лишь при условии, что прием и получение файлов cookie разрешены пользователем.</span>
                  <span>6.	СТОРОННИЕ ИНСТРУМЕНТЫ ВЕБ-АНАЛИТИКИ</span>
                  <span>6.1. SpeedyTrade также использует сторонние инструменты веб-аналитики для того, чтобы собирать информацию об источниках трафика, посещаемости сайта и оценки эффективности наших Служб. Мы используем: инструменты веб-аналитики Яндекс.Метрика и Google Analytics. Типы персональных данных, которые мы собираем таким образом: дата и время осуществления доступа к Сайту и/или Сервису, электронные данные (HTTP-заголовки, IP-адрес, файлы cookie, данные об идентификаторе браузера и программном обеспечении).</span>
                  <span>Подробнее о файлах cookie Яндекс.Метрики можно прочитать на странице https://yandex.ru/support/metrica/general/cookie-usage.html. Подробнее о файлах cookie Google Analytics можно прочитать на странице https://policies.google.com/technologies/partner-sites?hl=ru.</span>
                  <span>7.	БЕЗОПАСНОСТЬ ПЕРСОНАЛЬНЫХ ДАННЫХ</span>
                  <span>7.1. SpeedyTrade защищает хранимые персональные данные от разглашения, полной или частичной утраты, а также несанкционированного доступа третьих лиц в строгом соответствии с применимым законодательством.</span>
                  <span>7.2. SpeedyTrade использует все необходимые технические и организационные меры по обеспечению безопасности и конфиденциальности хранимых персональных данных и постоянно актуализирует их с учетом последних технических разработок.</span>
                  <span>7.3. Если происходит утечка персональных данных, SpeedyTrade:</span>
                  <span>в течение 24 часов уведомляет об этом Роскомнадзор;</span>
                  <span>в течение 72 часов проводит собственное расследование и уведомляет о его результатах Роскомнадзор.</span>
                  <span>8. ОГРАНИЧЕНИЕ ПОЛИТИКИ</span>
                  <span>8.1. Политика применима только к Сайту SpeedyTrade и не распространяется на какие-либо другие сторонние веб-сайты, в т.ч. в случаях когда:</span>
                  <span>сторонний веб-сайт содержит ссылку на Сайт SpeedyTrade;</span>
                  <span>Сайт SpeedyTrade содержат ссылку на сторонний веб-сайт.</span>
                  <span>8.2. Порядок обработки персональных данных на сторонних веб-сайтах производится в соответствии с собственными правилами конфиденциальности, которые могут отличаться от данной Политики. В связи с этим рекомендуем вам ознакомиться с этими правилами стороннего веб-сайта при переходе на него.</span>
                </Row>
              </div>
            </div>
          </Row>
        </section>
      </Container>
    </main>
  );
};

export default PrivacyPolicy;