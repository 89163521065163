import React from 'react';
import { Row, Table } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

const Rules = () => {
  return (
    <main>
      <Container>
        <section className='rules mb-md-5'>
          <Row className='justify-content-center'>
            <div className='topic mb-4 mb-md-5'>
              <h1 className='h2 mb-0'>Правила Сайта</h1>
            </div>
            <div className='rules-wrapping mb-5'>
              <div className='rules-wrapping-top'>
              </div>
              <div className='rules-wrapping-main'>
                <div>
                  <p>Настоящий документ (далее — Правила Сайта, Правила, Правила Сервиса), является частью публичной оферты, размещенной по адресу https://speedytrade.ru/rules. Раскрытие всех терминов и определений, общих для Правил и Соглашения, приведено в Соглашении.</p>
                  <Table responsive striped borderless>
                    <thead>
                      <tr>
                        <th>Нарушение</th>
                        <th>Санкции</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          1. Запрещается размещать на сайте заведомо ложную информацию о товарах или услугах, их наличии, качестве, количестве, характеристиках.
                        </td>
                        <td>
                          Предупреждение, блокировка аккаунта.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          2. Запрещается вводить в заблуждение, обманывать пользователей и администрацию Сайта.
                        </td>
                        <td>
                          Блокировка аккаунта и отказ в выплатах. Блокировка всех новых аккаунтов при их обнаружении.
                        </td>
                      </tr>

                      <tr>
                        <td>
                          3. Запрещается накручивать количество совершенных сделок и отзывов. Безосновательно изменять отзывы по старым заказам.
                        </td>
                        <td>
                          Удаление отзыва. Удаление всех отзывов. Блокировка аккаунта при повторном нарушении.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          4. Запрещается отправлять пользователям личные данные, контакты и ссылки на сторонние сайты и сервисы любыми способами.
                        </td>
                        <td>
                          Предупреждение. Блокировка аккаунта и отказ в выплатах при повторном нарушении.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          5. Передача третьим лицам какой-либо информации о пользователях или сделках (совершенных или несовершенных) с целью нанесения вреда пользователям.
                        </td>
                        <td>
                          Блокировка аккаунта и отказ в выплатах. Блокировка всех новых аккаунтов при их обнаружении.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          6. Размещение в любом виде порнографической, противозаконной и политической информации.
                        </td>
                        <td>
                          Удаление информации и сообщений. При повторном нарушении блокировка аккаунта.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          7. Запрещается осуществлять обмен денежных средств из одной платёжной системы в другую с целью совершения финансовых махинаций.
                        </td>
                        <td>
                          Блокировка аккаунта и отказ в выплатах. Блокировка всех новых аккаунтов при их обнаружении.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          8. Запрещается размещать объявления на других площадках.
                        </td>
                        <td>
                          Предупреждение, блокировка аккаунта.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          9. Запрещается продажа товаров, полученных посредством обмана других пользователей или полученных любым другим незаконным путем.
                        </td>
                        <td>
                          Блокировка аккаунта и отказ в выплатах. Блокировка всех новых аккаунтов при их обнаружении.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          10. Запрещена продажа и распространение персональных данных, телефонных номеров, нелицензионного и/или вредоносного ПО, порнографии, эротики, информации связанной со ставками и казино, «рандомов» и любой другой информации, товара или услуги которые противоречат действующим законодательствам.
                        </td>
                        <td>
                          Блокировка аккаунта и отказ в выплатах. Блокировка всех новых аккаунтов при их обнаружении.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          11. Запрещается продажа и распространение обучений о неправомерной деятельности.
                        </td>
                        <td>
                          Блокировка аккаунта и отказ в выплатах. Блокировка всех новых аккаунтов при их обнаружении.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          12. Запрещена продажа товаров, нарушающих исключительные права и лицензионные соглашения третьих лиц без разрешения правообладателя.
                        </td>
                        <td>
                          Блокировка аккаунта.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          13. Запрещается неуважительное общение и угрозы в сторону Администрации сайта
                        </td>
                        <td>
                          Предупреждение, блокировка аккаунта.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          14. Запрещается отправка спама в Службу поддержки сайта
                        </td>
                        <td>
                          Предупреждение, блокировка аккаунта и отказ в выплатах.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          15. Запрещается спам, флуд, ненормативная лексика, оскорбления, политические обсуждения. В общем чате запрещается отправка ссылок на лоты, реклама торговых интернет-ресурсов, а также любые сообщения, нарушающие действующее законодательство.
                        </td>
                        <td>
                          Предупреждение и блокировка сообщений. При многократном нарушении блокировка аккаунта.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          16. Запрещается осуществлять сделки с целью написания негативных отзывов, которые не соответствуют действительности (борьба с конкурентами и тп).
                        </td>
                        <td>
                          Предупреждение, блокировка аккаунта.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          17. Запрещается дублирование объявлений. Размещение объявлений о покупке.
                        </td>
                        <td>
                          Удаление объявлений, предупреждение, блокировка аккаунта.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          18. Запрещается предоставление финансов и/или товара/услуги участнику сделки до момента согласия и подтверждения сделки обеими сторонами, на сторонних ресурсах и/или в авансовом порядке. В этом случае администрация проекта не сможет вернуть средства и аннулировать сделку.
                        </td>
                        <td>
                          Отказ в поддержке и невозможность последующего обращения по сделке в поддержку. Блокировка аккаунта.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          19. Запрещается размещать объявления в непредназначенных для этого разделах.
                        </td>
                        <td>
                          Предупреждение, блокировка аккаунта.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          20. Запрещен призыв к совершению сделок или общению за пределами SpeedyTrade в мессенджерах, социальных сетях, форумах, других площадках.
                        </td>
                        <td>
                          Предупреждение, блокировка аккаунта и отказ в выплатах при повторном нарушении.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          21. Запрещается размещать объявления с не существующими товарами или услугами с целью демпинга и попытки искусственного изменения рыночных цен.
                        </td>
                        <td>
                          Предупреждение, удаление объявлений, блокировка аккаунта.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          22. Запрещается подтверждение сделки до получения товара или услуги в полном объеме.
                        </td>
                        <td>
                          Отказ в поддержке и невозможность последующего обращения по сделке в поддержку. В этом случае администрация проекта не сможет вернуть средства и аннулировать сделку.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          23. Запрещается требовать подтверждение сделки у второй стороны до предоставления услуги в полном объеме
                        </td>
                        <td>
                          Предупреждение, блокировка;
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          В случае блокировки аккаунта для проведения внутреннего расследования, все денежные операции, ожидающие вывода, отменяются и средства возвращаются на внутренний баланс Сервиса в рамках аккаунта замороженного пользователя.
                          Возврат денежных средств осуществляется при наличии таковых на счете нарушителя, в иных случаях возврат не может быть осуществлен.
                          Администрация оставляет за собой право обращения в правоохранительные органы для преследования нарушителей и злоумышленников, включая передачу всей персональной информации мошенника.
                          При нарушении правил сервиса администрация вправе изменять персональные условия пользования площадкой для нарушителя.
                          Если вы хотите обменяться контактными данными с другим продавцом для совместного выполнения заказов, подайте заявку в службу поддержки.
                          Администрация оставляет за собой право на связь с продавцами под видом покупателей с целью выявления нарушений.
                          Администрация оставляет за собой право проводить проверки размещаемых на площадке товаров.
                          Администрация оставляет за собой право смягчить или отменить любое наложенное наказание, если оно нецелесообразно в каком-либо конкретном случае.
                          Каждый спор между покупателем и продавцом индивидуален, поэтому окончательное решение о степени ответственности продавца может существенно отличаться от написанного выше.
                          Договорённости в чате между продавцом и покупателем могут быть приняты во внимание при решении спорных вопросов.

                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  {/* 
                        <button type="button" className="btn-5 fs-11 mt-4 mt-sm-5">
                            Принять условия
                        </button> */}
                </div>
              </div>
            </div>
          </Row>
        </section>
      </Container>
    </main>
  );
};

export default Rules;